<template>
  <div class="form">
    <a-breadcrumb>
      <a-breadcrumb-item
        ><router-link to="/drainage/emergancy/riskMember">应急预案</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item><a href="">新增应急预案</a></a-breadcrumb-item>
    </a-breadcrumb>

    <Padding :size="'large'" />

    <a-form
      :form="form"
      @submit="handleSubmit"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      :colon="false"
    >
      <a-row>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item
            label="名称"
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-input
              v-decorator="[
                'name',
                {
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item
            label="措施"
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-textarea
              :auto-size="{ minRows: 3 }"
              v-decorator="[
                'method',
                {
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="类型">
            <a-select
              v-decorator="[
                'type',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
              style="width: 100%"
            >
              <a-select-option
                v-for="item in typeList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="等级">
            <a-select
              v-decorator="[
                'level',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
              style="width: 100%"
            >
              <a-select-option
                v-for="item in levelList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="预警规则">
            <a-select
              v-decorator="[
                'standard',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
              style="width: 100%"
            >
              <a-select-option
                v-for="item in form.getFieldValue('type') === 'rainFall'
                  ? rainFallList
                  : liquidLevelList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="预警值">
            <a-input-number
              style="width: 100%"
              v-decorator="[
                'value',
                {
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item
            label="文件"
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 21 }"
          >
            <!-- planFile -->
            <a-button type="primary">上传</a-button>
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button htmlType="submit" type="primary">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import { add } from "@/api/warning";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("riskType");
    },
    levelList() {
      return this.findDataDict("riskLevel");
    },
    rainFallList() {
      return this.findDataDict("riskPlan.rainFall");
    },
    liquidLevelList() {
      return this.findDataDict("riskPlan.liquidLevel");
    },
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          add({
            ...values,
          }).then(() => {
            this.cancel();
          });
        }
      });
    },

    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
